.page-container[data-page-route=site-usage-charts] .list-filters {
  overflow-y: hidden;
  padding: 5px;
}
.page-container[data-page-route=site-usage-charts] .list-filter-item {
  min-width: 150px;
  float: left;
  margin: 5px;
}
.page-container[data-page-route=site-usage-charts] .list-item_content {
  flex: 1;
  padding-right: 15px;
  align-items: center;
}
.page-container[data-page-route=site-usage-charts] .select-time,
.page-container[data-page-route=site-usage-charts] .select-doctype,
.page-container[data-page-route=site-usage-charts] .select-filter,
.page-container[data-page-route=site-usage-charts] .select-sort {
  background: #f0f4f7;
}
.page-container[data-page-route=site-usage-charts] .from-date-field .clearfix {
  display: none;
}
.page-container[data-page-route=site-usage-charts] .from-date-field {
  margin-left: 10px;
}
.page-container[data-page-route=site-usage-charts] .select-time:focus,
.page-container[data-page-route=site-usage-charts] .select-doctype:focus,
.page-container[data-page-route=site-usage-charts] .select-filter:focus,
.page-container[data-page-route=site-usage-charts] .select-sort:focus {
  background: #f0f4f7;
}
.page-container[data-page-route=site-usage-charts] .site-usage-charts-list {
  padding: var(-padding-sm) 0;
}
.page-container[data-page-route=site-usage-charts] .site-usage-charts-no-data-state {
  align-items: center;
  height: 70vh;
  justify-content: center;
  display: flex;
}
.page-container[data-page-route=site-usage-charts] .site-usage-charts-list .datatable {
  padding: 2px;
  margin: auto;
  overflow: scroll !important;
}
.page-container[data-page-route=site-usage-charts] details {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.page-container[data-page-route=site-usage-charts] details > summary span.details-icon {
  width: 24px;
  height: 24px;
  margin-left: auto;
}
.page-container[data-page-route=site-usage-charts] details[open] summary span.details-icon {
  transform: rotateX(180deg);
}
.page-container[data-page-route=site-usage-charts] summary {
  display: flex;
  cursor: pointer;
  align-items: baseline;
  gap: 1rem;
}
.page-container[data-page-route=site-usage-charts] summary::-webkit-details-marker {
  display: none;
}
.page-container[data-page-route=site-usage-charts] .dt-row-header .dt-cell__content {
  text-align: center;
  font-weight: 700 !important;
}
.page-container[data-page-route=site-usage-charts] .dt-scrollable .dt-cell__content {
  text-align: right;
}
.page-container[data-page-route=site-usage-charts] .datatable .dt-scrollable {
  border-bottom: 1px solid var(--table-border-color) !important;
  min-height: 80px !important;
  height: auto !important;
}